import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { FiFacebook, FiGithub, FiLinkedin, FiTwitter } from "react-icons/fi"
import Layout from "../components/layout"
import ProfileImage from "../components/profile"
import SEO from "../components/seo"
import "../sass/main.scss"

const IndexPage = () => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true)
    }, 16)
    return () => {
      setLoaded(false)
      clearTimeout(timer)
    }
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <div className="grid-x grid-padding-x align-center">
        <div
          className={`cell large-5 medium-6 small-12 homepage ${
            loaded ? "loaded" : ""
          }`}
        >
          <div className="image card">
            <ProfileImage />
          </div>
          <h1 className="title">Joshua Nazareth</h1>
          <div className="social">
            <a
              href="https://github.com/joshuanazareth97"
              rel="noreferrer"
              target="_blank"
            >
              <FiGithub />
            </a>
            <a
              href="https://twitter.com/joshuanazareth4"
              rel="noreferrer"
              target="_blank"
            >
              <FiTwitter />
            </a>
            <a
              href="https://www.linkedin.com/in/joshuanazareth"
              rel="noreferrer"
              target="_blank"
            >
              <FiLinkedin />
            </a>
          </div>
          <article className="about-me">
            <p>
              Hey there! My name is Joshua Nazareth and you've stumbled upon my
              small corner of the internet. That's awesome!
            </p>
            <p>
              I have a B.Sc. degree in Information Technology and since
              graduating, I've transitioned from passionate student into a
              professional role as a developer.
            </p>
            <p>
              Over the past 3 years, I've gained hands-on experience, tackling
              diverse challenges and enhancing my skills, by building features
              for a variety of companies. This journey has been instrumental in
              my growth, equipping me with the insights and abilities to
              contribute effectively to projects. I'm keen to apply what I've
              learned and continue developing software that makes a difference.
            </p>
            <p>
              Still reading and interested in learning more? See the{" "}
              <Link to="/projects" className="intro-link">
                things I've built
              </Link>
              , or{" "}
              <Link to="/favs" className="intro-link">
                head here
              </Link>{" "}
              to learn more about me! Like my work?{" "}
              <Link to="/contact" className="intro-link">
                Get in touch.
              </Link>
            </p>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
